'use client';

import { setLocaleAction } from '@/actions';
import { useAuth } from '@/lib/client/hooks/useAuth';
import { useAccountStore } from '@/lib/client/store/account.store';
import {
  storeCurrentBusiness,
  storeCurrentProfile,
} from '@/lib/utils/functions/authentication';
import { useListBusinessesForUser } from '@/modules/business/business.queries';
import { useProfile } from '@/modules/profile/profile.queries';
import { useLocale } from 'next-intl';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

function isPublicPage(
  pathname: string,
  params: Record<string, string | string[]>
) {
  if (pathname.startsWith(`/b/${params.business_slug}`)) {
    return true;
  }

  if (pathname.startsWith(`/jobs`)) {
    return true;
  }

  return false;
}

function getStoredAccount() {
  if (typeof window === 'undefined') return;

  const accountStr = localStorage.getItem('jr_account');

  if (accountStr) {
    try {
      const account = JSON.parse(accountStr);

      if ('type' in account && 'hash' in account) {
        return account;
      }
    } catch (err: any) {}
  }
}

export function AccountProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const params = useParams();
  const pathname = usePathname();
  const locale = useLocale();

  const [currentPath, setCurrentPath] = useState(pathname);
  const { isLoggedIn, isLoading: isAuthLoading } = useAuth();
  const { setAccount, ...account } = useAccountStore();
  const [canRun, setCanRun] = useState(true);
  const { data: businesses, isLoading: isBusinessesLoading } =
    useListBusinessesForUser();
  const { data: profile, isLoading: isProfileLoading } = useProfile();

  const isLoading = isAuthLoading || isBusinessesLoading || isProfileLoading;

  const fetchAccount = useCallback(async () => {
    const account = {
      isLoading: false,
      initialized: true,
    };

    if (businesses?.length) {
      storeCurrentBusiness(businesses[0].hash);
      setAccount({
        hash: businesses[0].hash,
        type: 'business',
        ...account,
      });
      return true;
    }

    if (!!profile) {
      storeCurrentProfile(profile.hash);
      setAccount({
        hash: profile.hash,
        type: 'candidate',
        ...account,
      });
      return true;
    }

    setAccount(account);
    return false;
  }, [businesses, profile]);

  useEffect(() => {
    if (pathname.startsWith('/auth')) return;

    if (account.hash && account.type) {
      if (account.isLoading || !account.initialized) {
        setAccount({
          hash: account.hash,
          type: account.type,
          isLoading: false,
          initialized: true,
        });
      }
      return;
    }

    if (isLoading) {
      return;
    }

    if (!canRun) {
      return;
    }

    setCanRun(false);

    if (!isLoggedIn) {
      setAccount({ isLoading: false, initialized: true });
      return;
    }

    let storedAccount = getStoredAccount();

    if (storedAccount) {
      if (storedAccount.type === 'business') {
        storeCurrentBusiness(storedAccount.hash);
        setAccount({ ...storedAccount, initialized: true, isLoading: false });
        return;
      }

      if (storedAccount.type === 'candidate') {
        storeCurrentProfile(storedAccount.hash);
        setAccount({ ...storedAccount, initialized: true, isLoading: false });
        return;
      }
    }

    fetchAccount().then((hasAccount) => {
      if (
        !hasAccount &&
        !location.pathname.startsWith('/onboarding') &&
        !isPublicPage(pathname, params)
      ) {
        router.push(`/onboarding/mode`);
      }
    });
  }, [isLoading, isLoggedIn, account, canRun, fetchAccount, pathname, params]);

  useEffect(() => {
    if (pathname === currentPath) return;
    setCurrentPath(pathname);
    setCanRun(true);
  }, [pathname, currentPath]);

  useEffect(() => {
    if (
      account.type !== 'business' ||
      locale === 'en' ||
      !pathname.startsWith('/app')
    )
      return;

    setLocaleAction('en');
  }, [account, locale, pathname]);

  return <>{children}</>;
}

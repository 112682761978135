import {
  DefaultError,
  InfiniteData,
  QueryClient,
  QueryKey,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from './useAuth';
import { useMemo } from 'react';
import { InfiniteQueryOptions, QueryOptions } from '@/lib/types';

export function useAuthedQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient
) {
  const { isLoggedIn } = useAuth();

  const isEnabled = useMemo(() => {
    if (!isLoggedIn) return false;
    if (options.enabled === undefined) return true;
    return options.enabled;
  }, [isLoggedIn, options.enabled]);

  return useQuery<TQueryFnData, TError, TData, TQueryKey>(
    {
      ...options,
      enabled: isEnabled,
    },
    queryClient
  );
}

export function useAuthedInfiniteQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  options: InfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey,
    TPageParam
  >,
  queryClient?: QueryClient
) {
  const { isLoggedIn } = useAuth();

  const isEnabled = useMemo(() => {
    if (!isLoggedIn) return false;
    if (options.enabled === undefined) return true;
    return options.enabled;
  }, [isLoggedIn, options.enabled]);

  return useInfiniteQuery(
    {
      ...options,
      enabled: isEnabled,
    },
    queryClient
  );
}

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"variable\":\"--font-moderat\",\"display\":\"swap\",\"fallback\":[\"system-ui\",\"sans\"],\"src\":[{\"path\":\"../../public/assets/fonts/moderat/Moderat-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Light.woff\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Regular.ttf\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Bold.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Bold.woff\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/moderat/Moderat-Bold.ttf\",\"weight\":\"bold\",\"style\":\"normal\"}]}],\"variableName\":\"moderat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"variable\":\"--font-spirit\",\"display\":\"swap\",\"fallback\":[\"serif\"],\"src\":[{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-light.woff\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-semibold.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-bold.woff\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-heavy.woff2\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/new-spirit/newspirit-heavy.woff\",\"weight\":\"900\",\"style\":\"normal\"}]}],\"variableName\":\"newSpirit\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"variable\":\"--font-flow\",\"display\":\"swap\",\"fallback\":[\"sans\"],\"src\":[{\"path\":\"../../public/assets/fonts/flow/flowrounded-regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/flow/flowrounded-regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"}]}],\"variableName\":\"flow\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/banner/Banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccountProvider"] */ "/app/src/components/providers/account-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/providers/datadog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PlayerProvider"] */ "/app/src/components/providers/player-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/app/src/components/providers/query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BoundStoreProvider"] */ "/app/src/components/providers/store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/src/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/lib/style/globals.css");

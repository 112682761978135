import {
  getTokenPayload,
  isAuthenticated,
} from '@/lib/utils/functions/authentication';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { use, useEffect, useState } from 'react';

export function useAuth(
  { require }: { require?: boolean } = { require: false }
) {
  const router = useRouter();
  const {
    data: isLoggedIn,
    isLoading: isAuthLoading,
    isRefetching,
    isFetching,
  } = useQuery({
    queryKey: ['auth'],
    queryFn: isAuthenticated,
  });
  const [userId, setUserId] = useState<string | undefined>();
  const [sessionId, setSessionId] = useState<string | undefined>();
  const isLoading = isAuthLoading || isFetching || isRefetching;

  useEffect(() => {
    const cookies = document.cookie
      .split(';')
      .filter((cookie) => cookie.trim().startsWith('__sess='));

    const sessionId = cookies.at(0)?.split('=')[1];

    setSessionId(sessionId);
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (!isLoggedIn && require) {
      router.replace(`/auth/sign-in`);
      return;
    }

    const payload = getTokenPayload();

    setUserId(payload.userId);
  }, [isLoggedIn, isLoading, require]);

  return {
    isLoggedIn,
    sessionId,
    userId,
    isLoading,
  };
}

import { getBearerToken } from '@/lib/utils/functions/authentication';
import jobreelApi from '@/lib/utils/jobreel-api';
import { BusinessSubscription } from '@/modules/business/business.model';

export async function getBusinessSubscriptionStatus(businessHash: string) {
  const { data } = await jobreelApi.get<BusinessSubscription>(
    `/v2/businesses/${businessHash}/subscription`,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );

  return data;
}

export async function createCheckoutSession(
  businessHash: string,
  params?: { quantity?: number }
) {
  const { data } = await jobreelApi.post<{ sessionUrl: string }>(
    `/v2/businesses/${businessHash}/subscription/create-checkout-session`,
    {},
    {
      headers: {
        Authorization: getBearerToken(),
      },
      params: {
        q: params?.quantity,
      },
    }
  );

  return data;
}

export async function createPortalSession(businessHash: string) {
  const { data } = await jobreelApi.post<{ portalUrl: string }>(
    `/v2/businesses/${businessHash}/subscription/create-portal-session`,
    {},
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );

  return data;
}

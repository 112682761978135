import { useQuery } from '@tanstack/react-query';
import {
  Business_Current,
  Business_FindBySlug,
  Business_GetCategories,
  Business_GetLink,
  Business_ListForUser,
  Business_Retrieve,
} from '@/chore/services/Business.service';
import { getCurrentBusiness } from '@/lib/utils/functions/authentication';
import { QueryConfig } from '@/lib/types';
import { Business } from './business.model';
import { getBusinessSubscriptionStatus } from '@/chore/services/v2/Business.service';
import { useAuthedQuery } from '@/lib/client/hooks/useAuthedQuery';
export function useBusinessByHash(businessHash: string) {
  const query = useAuthedQuery({
    queryKey: [`business-${businessHash}`],
    queryFn: async () => await Business_Retrieve(businessHash ?? ''),
    enabled: !!businessHash.length,
  });

  return query;
}

export function useBusinessBySlug(businessSlug: string) {
  const query = useQuery({
    queryKey: [`business-${businessSlug}`],
    queryFn: async () => await Business_FindBySlug(businessSlug),
    enabled: !!businessSlug.length,
  });

  return query;
}

export function useListBusinessesForUser(config?: QueryConfig<Business[]>) {
  const query = useAuthedQuery({
    ...config,
    queryKey: ['list-businesses-for-user'],
    queryFn: Business_ListForUser,
  });
  return query;
}

export function useBusinessCategories() {
  return useAuthedQuery({
    queryKey: ['business-categories'],
    queryFn: Business_GetCategories,
  });
}

export function useCurrentBusiness() {
  return useAuthedQuery({
    queryKey: ['business-current'],
    queryFn: Business_Current,
    enabled: !!getCurrentBusiness(),
  });
}

export function useBusinessLink(businessHash: string) {
  return useAuthedQuery({
    queryKey: ['business-link', businessHash],
    queryFn: async () => await Business_GetLink(businessHash),
  });
}

export function useSubscription() {
  const { data: business } = useCurrentBusiness();
  return useAuthedQuery({
    queryKey: ['business-subscription-status', business?.hash],
    queryFn: async () => await getBusinessSubscriptionStatus(business!.hash),
    retry: false,
    enabled: !!business?.stripeCustomerId,
  });
}
